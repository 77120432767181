// $platform-name: 'sutherlandshirefamilyservicesltd';

// test comment

//
// Environment
//

//
// Config values
//
$platform-name: "sutherlandshirefamilyservicesltd";
$assets-path: "../assets/";
$warn: false;

//
// Colours
//
// $brand-primary: #5e3488;
// $brand-secondary: #8c68a8;
// $light_purple: #beabcf;

$brand-primary: #97CA3D;
$brand-secondary: #008067;

$grey: #808285;
$donate-colour: $brand-primary;

// Create greyscale

// Utility colours

//
// Layout
//

$container-max-width: 1230px;

$site-bleed: 20px;

// Breakpoints
$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1200px
);

// Spacers
$spacer: 1rem;
$spacer-y: $spacer;
$spacer-x: $spacer;
//
// Misc
//
$body-colour: #000;
//
// Transitions
//

//
// Logo
// $logo: "logo.svg";
$logo: "SSFS_Logo.svg";

$logo-width: 280px;
$logo-height: 110px;
$logo-absolute: true;

//
// Typography
//

@font-face {
  font-family: 'Nutmeg';
  src: url($assets-path + 'NutmegRegular.woff2') format('woff2'),
       url($assets-path + 'NutmegRegular.woff') format('woff');
  font-weight: normal;
}

@font-face {
  font-family: 'Nutmeg';
  src: url($assets-path + 'NutmegBold.woff2') format('woff2'),
       url($assets-path + 'NutmegBold.woff') format('woff');
  font-weight: bold;
}

$font-size-base: 0.95rem;
$font-family-base: "Montserrat", sans-serif;
$headings-font-family: "Nutmeg", sans-serif;
$headings-colour: $brand-secondary;
$font-weight-bold: 900;
// Links

// Icon font

//
// Buttons
//
$btn-background-colour: $brand-secondary;
$border-radius: 3px;
$btn-border-radius: $border-radius;
$btn-padding-x: 1em;
$btn-padding-y: 0.75em;
$btn-text-colour: white;
// $btn-font-family: $font-family-base;
$btn-font-family: $headings-font-family;
$btn-font-weight: $font-weight-bold;
// Donate button
$donate-btn-colour: white;
//
// Social icons
// Documentation: https://fresco-docs.netlify.com/#/header/social-icons/
//
$social-icons-border-radius: 100px;
$social-icons-width: 30px;
$social-icons-height: 30px;
$social-icons-font-size: 1rem;

$social-icons-background-colour: $brand-secondary;
$social-icons-colour: #fff;
// Social network colours - use these by changing $social-icons-use-brand-colours to true

// Header specific

// Footer specific social icons
$social-icons-footer-background-colour: transparent;
$social-icons-footer-colour: white;
//
// Share this page
//

//
// Form elements
//

// Field groups

//
// Tables
//

//
// Card
//
$card-border-radius: $border-radius;
$card-heading-colour: $brand-secondary;
$card-hover-image-opacity: 1;
$card-hover-image-scale: 1.1;
$card-hover-details-background-colour: $brand-primary;
$card-hover-heading-colour: white;
$card-border: 0;
$card-min-width: 200px;

// Card text overlay

// Card text over

// Card side by side

// Card hover state

// Event cards

//
// Menu admin
//
$menu-admin-enabled: false;
//
// Header
//

// Header content
$header-content-margin-bottom: 0;
$header-content-padding-top: 10px;
$header-content-padding-bottom: 10px;
$header-content-margin-top: 0;

$header-background-colour: $brand-secondary;

// Tagline

// Main call to action
$cta-btn-gap: 20px;
$cta-btn-colour: white;
// Search
$header-search-margin-right: 5px;

// Search - input
$header-search-input-max-width: 0;
$header-search-input-margin-right: 0;
$header-search-input-padding: 0px 0px;
$header-search-input-border-colour: #fff;
$header-search-input-margin-right: 0px;

// Search - button
// $header-search-button-background-colour: #989898;
$header-search-button-background-colour: transparent;
$header-search-button-icon-colour: #fff;
$header-search-button-border-radius: 100px;
$header-search-button-width: 30px;
$header-search-button-height: 30px;
// Search when in nav

// Social icons

//
// Sticky header
//

//
// Navigation
//
$nav-type: normal;
$nav-breakpoint: map-get($breakpoints, lg);
$nav-background-colour: transparent;
$nav-normal-align-items: right;
$nav-top-level-item-colour: #fff;
$nav-font-family: $font-family-base;

$nav-submenu-item-colour: $brand-secondary;
// Top level items
$nav-top-level-item-padding: 12.5px 20px;
// Submenus

// Burger button

// Nav normal

//
// Specifically when no banner present and you have gone for $header-absolute: true
//

//
// Carousel
//
$carousel-max-width: 100%;
$carousel-contents-max-width: $container-max-width;
$carousel-details-position-y: center;
$carousel-details-position-x: left;
$carousel-details-background-colour: transparent;
$carousel-details-box-shadow: none;
$carousel-details-max-width: 500px;
$carousel-heading-colour: #fff;
$carousel-summary-colour: #fff;

$carousel-controls-position-y: center;
$carousel-controls-position-x: split;
$carousel-controls-margin-x: $site-bleed;
$carousel-controls-icon-colour: #fff;
$carousel-read-more-enabled: false;
$carousel-heading-font-size: 3rem;

$carousel-mobile-details-background-colour: $brand-secondary;
// Carousel controls (left-right buttons)
$carousel-controls-button-background-colour: transparent;
$carousel-controls-icon-colour: white;
// Carousel buttons (dots or tabs)

// Carousel buttons dots
$carousel-dots-padding: 2rem;
// Carousel buttons tabs

// Carousel video play button (before breakpoint, when carousel details present)

// Carousel scroll button

// Carousel after breakpoint
$carousel-mobile-heading-colour: #fff;
$carousel-mobile-summary-colour: #fff;

// Banner min-height under sm breakpoint - helps to keep some vertical height at narrow viewports, especially for letter-box aspect ratios

//
// Home intro
//
$home-intro-background-colour: white;
$home-intro-colour: $brand-secondary;
$home-intro-font-size: 1rem;
$home-intro-padding-y: $spacer * 1;
// $home-intro-text-transform: uppercase;
$home-intro-margin-top: 3rem;

//
// Home features
//

$home-features-padding-top: 0;
$home-features-padding-bottom: $spacer * 3;
$impact-stats-background-image: "impact_bg.jpg";
$impact-stats-background-image-opacity: 0.1;

//
// Impact stats
//
$impact-stats-padding-y: $spacer * 3;
$impact-stats-colour: white;
// Heading
$impact-stats-heading-font-size: 2.5em;
// Individual stat

// Figure
$impact-stats-figure-font-family: $font-family-base;
$impact-stats-figure-font-size: 4.5em;
$impact-stats-figure-colour: white;
// Summary
$impact-stats-summary-colour: white;
//
// Home feeds
//
$home-feeds-background-colour: #ece9e9;
$home-feeds-padding-top: $spacer * 2;
$home-feeds-padding-bottom: $spacer * 2;

$feeds-title-font-size: 2.5em;
// Feeds title
$feeds-title-colour: $brand-secondary;

// Feed item

// Event feed items
$feed-item-event-start-date-enabled: false;
$feed-item-event-location-enabled: false;
// Twitter feed

//
// Footer
//

$footer-prefab: 5;
$footer-padding-y: $spacer * 3;
// $footer-background-colour: rgba(#eceeef, .3);
$footer-background-colour: $brand-secondary;
$footer-colour: white;
$footer-link-colour: white;

// Footer admin links

// Newsletter
$newsletter-use-placeholders: true; // Turn on to use placeholders in the inputs rather than labels
$newsletter-background-colour: transparent;
$newsletter-colour: #fff;
$newsletter-heading-text-align: left; // left, center, right
$newsletter-heading-font-size: 1.5em;
$newsletter-heading-colour: white;
$newsletter-heading-margin-bottom: $spacer * 1.5;
$newsletter-button-height: 46px; // Hard code this so you can match it with the inputs
$newsletter-button-colour: white;
$newsletter-button-background-colour: $brand-primary;
// Newsletter hero - full width form above rest of footer

//
// Context container
//

// Donors list

//
// Posts
//

//
// Header text (container for breadcrumb and page title)
//

//
// Page title
//

//
// Sidebar
//

// Associated lists

//
// Listing
//

// Listed post settings

//
// Breadcrumb
//

//
// Blockquote
//

//
// Fundraising
//

// Find a fundraiser widget

// Top fundraisers

//
// Donation form
//

// Donation amounts

//
// Quick giving panel
//

//
// Home quick giving panel
//
$home-quick-giving-donation-amount-figure-colour: white;
$home-quick-giving-donation-amount-background-colour: $brand-secondary;
$home-quick-giving-donate-btn-background-colour: $donate-colour;

// Adjacent homepage quick giving

// Overlay homepage quick giving

//
// Totalisers
//

//
// Comments
//

//
// Blog details
//

//
// FAQs
//

//
// Shop
//

// Departments list

// Product-price

// Product post

//
// Subsite

$subsite-enabled: true;
$subsite-hide-main-header: false;
$subsite-hide-main-tagline: true;
$subsite-hide-header-search: true;
$subsite-hide-main-cta: true;

$subsite-content-max-width: $container-max-width;
// $subsite-title-font-size: $font-size-base;

// Subsite logo
// $subsite-logo: "logo.png";
$subsite-logo-width: 120px;
$subsite-logo-height: 120px;
$subsite-logo-margin: $spacer 0;

// Subsite nav
$subsite-nav-breakpoint: $nav-breakpoint;
$subsite-nav-max-width: $container-max-width;
$subsite-nav-margin-top: $spacer * -5.5;
$subsite-nav-margin-bottom: $spacer * 3;
$subsite-nav-background-colour: transparent;
$subsite-nav-contents-max-width: $container-max-width;
$subsite-nav-align-items: right;
$subsite-nav-top-level-item-padding: 10px 20px;
$subsite-nav-top-level-item-colour: $body-colour;
$subsite-nav-top-level-item-font-family: $nav-font-family;
$subsite-nav-top-level-item-font-size: 1rem;
// $subsite-nav-top-level-item-font-weight: $nav-top-level-item-font-weight !default;
$subsite-nav-top-level-item-background-colour: transparent;
$subsite-nav-top-level-item-hover-colour: $brand-primary;
$subsite-nav-top-level-item-hover-background-colour: transparent;

// $subsite-nav-submenu-width: $nav-submenu-width !default;
// $subsite-nav-submenu-background-colour: $nav-submenu-background-colour !default;
// $subsite-nav-submenu-box-shadow: $nav-submenu-box-shadow !default;
// $subsite-nav-submenu-border-radius: $nav-submenu-border-radius !default;
// $subsite-nav-submenu-item-padding: $nav-submenu-item-padding !default;
// $subsite-nav-submenu-item-colour: $nav-submenu-item-colour !default;
// $subsite-nav-submenu-item-font-family: $nav-submenu-item-font-family !default;
// $subsite-nav-submenu-item-font-size: $nav-submenu-item-font-size !default;
// $subsite-nav-submenu-item-font-weight: $nav-submenu-item-font-weight !default;
// $subsite-nav-submenu-item-hover-colour: $nav-submenu-item-hover-colour !default;
// $subsite-nav-submenu-item-hover-background-colour: $nav-submenu-item-hover-background-colour !default;


//
// Cookie consent
//
