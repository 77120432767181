//navigation

.menuMain {
  margin-bottom: 0rem;
}

// JK - 26/9
// https://www.bugherd.com/projects/175836/tasks/1
// Stop nav clashing into logo
@media (min-width: $nav-breakpoint + 1px) and (max-width: 1170px) {
  .mainLogo {
    height: 70px;
  }
}

.homeIntro h3,
.homefeaturecategory-homeboximpactstats .homeImpactWrapper>h2 {
  @include heading-underline($colour: white,
    $width: 50px,
    $height: 2px,
    $justify: center,
    $margin-top: 1rem,
    $margin-bottom: 0rem);
}

.homefeaturecategory-homeboximpactstats td:first-child h2 {
  @include heading-underline($colour: white,
    $width: 50px,
    $height: 2px,
    $justify: center,
    $margin-top: 1rem,
    $margin-bottom: 1rem);
}

.homeFeatureDetailsWrapper {
  position: relative;
}

.homefeaturecategory-homeboximpactstats .homeImpactWrapper>h2 {
  color: white;
}

// Stats styling
.homeImpactWrapper {
  .fa {
    font-size: 4em;
    margin-bottom: $spacer * 1;
    color: rgba($brand-secondary, 1);
  }

  table tbody {
    align-items: flex-start;
  }

  td:nth-child(1) {
    order: 2;
  }

  td:nth-child(2) {
    order: 3;
    padding: 0 40px;
  }

  td:nth-child(3) {
    order: 1;
  }

  table td h2 {
    font-size: 4em; // $impact-stats-figure-font-family
    line-height: 1.1;
    // Fix for impact stat figure
    color: white; // $impact-stats-figure-colour
  }
}

//impact

.homefeaturecategory-homeboximpactstats .homeImpactWrapper>h2 {
  margin-bottom: 0rem; // Use variables!
}

//feeds

.homeFeeds {
  padding: 2rem 0; // USE VARIABLES!
}

// Footer
.footerBox {
  h3 {
    font-size: 1.5em;
  }

  .charityNumber {
    font-size: 0.875em;
  }

  .socialIcons {
    //margin-left: -12px; // This is hacky. Better to use Flex justify-content
    flex-wrap: wrap;

    a {
      justify-content: flex-start;
    }
  }
}

.footerBox.footerBox2 {
  grid-column: 10 / span 3; // JK - why not use prefab?: https://fresco-docs.netlify.com/#/footer/4-box-layouts?a=footer-prefab-5

  @media (max-width: map-get($breakpoints, md)) {
    grid-column: 3 / span 6;
  }

  @media (max-width: map-get($breakpoints, sm)) {
    grid-column: 3 / span 12;
  }

  h3 {
    margin-bottom: $spacer * 1.5;
  }
}

.footerBox.footerBox3 {
  grid-column: 13 / span 2; // Prefab?

  p {
    font-size: 12px;
  }

  @media (max-width: map-get($breakpoints, md)) {
    grid-column: 9 / span 6;
  }

  @media (max-width: map-get($breakpoints, sm)) {
    grid-column: 3 / span 12;

    h3 {
      margin-bottom: $spacer * 1.5;
    }
  }
}

.footerBox.footerBox4 {
  border-top: 1px solid rgba(white, 0.3);
  font-size: 12px;

  .footerLinks {
    display: flex;

    li {
      font-size: 16px;
      padding: $spacer * 2 $spacer * 1 $spacer * 2 0;
    }

    @media (max-width: map-get($breakpoints, lg)) {
      li {
        font-size: 14px;
      }
    }

    @media (max-width: map-get($breakpoints, md)) {
      display: block;
      padding: $spacer * 2 0;

      li {
        padding: 0;
        font-size: 16px;
      }
    }
  }
}

.fr-logo {
  width: 160px;
  padding-top: 10px;
}

//quick exit

a.exit-site {
  padding: 2rem 2rem;
  background-color: #33454f;
  -webkit-transform: rotate(90deg) translate(50%, 0);
  transform: rotate(90deg) translate(50%, 0);
  -webkit-transform-origin: top right;
  transform-origin: top right;
  position: fixed;
  right: 0;
  bottom: 0;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  font-size: 18px;
  font-weight: 700;
  text-decoration: none;
}

.homefeaturecategory-homeboxquickgiving .quickGivingPanel .donateBtn {
  font-weight: 900;
  font-family: "Open Sans", sans-serif;
}








// Subsite
body.subsite {

  // Header - Layout
  .pageHeader .headerContent {
    padding-left: $site-bleed;
    margin-top: 0;
  }

  // Logo
  .mainLogo {
    position: relative;
    top: auto;
    left: auto;
    // background-image: url($assets-path + "logo-subsite-edys.png");
    width: 200px;
    height: 168.5px;

    @media (max-width: $logo-breakpoint) {
      width: $logo-width;
      height: $logo-height;
    }
  }

  // Mainsite - Navigation
  .menuMain {
    @media (min-width: $nav-breakpoint + 1px) {
      margin-top: $spacer * -3.5;
      padding-bottom: $spacer;
    }

    // Submenu
    @media (max-width: $nav-breakpoint) {

      ul.topLevel a,
      ul.topLevel a:after {
        color: $white;
      }
    }
  }

  // Feeds - Background
  .subsiteFeedsWrapper,
  .subsiteFeed {
    background-color: $white;
  }

  // Subsite - Navigation
  @media (max-width: $nav-breakpoint) {
    .menuSub {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  // Feeds - Layout
  .subsiteFeeds {
    max-width: $container-max-width;
  }

  // Feeds Title
  .homeFeed.subsiteFeed .feedsTitle {
    text-transform: uppercase;
    font-size: 1.75rem;

    &:after {
      background-color: $feeds-title-colour;
    }
  }

  // Sidebars
  .contentBlockWrapper .contentBlock {
    display: block;
  }

  .postAside {
    display: none;
  }

  // Footer - Background
  .pageFooterWrapper {
    background-color: $brand-secondary;
  }

  // Footer - Layout
  .pageFooter {
    display: block;
    width: 100%;
    max-width: $container-max-width;
  }

  .footerBox:not(.SubsiteFooter) {
    display: none;
  }

  .SubsiteFooter {
    position: relative;
    width: 100%;
    margin: 0;
    padding: 0 $site-bleed;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;

    @media (max-width: map-get($breakpoints, md)) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .subsiteFooterInner {
      flex: 1;
      margin: $spacer* 2 0;
      text-align: center;

      h2 {
        text-align: center !important;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        color: $brand-primary;
        font-size: 2em;

        &:before {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          color: #000;
          background-color: #FFF;
          font-family: 'FontAwesome';
          width: 25px;
          height: 25px;
          border-radius: 25px;
          font-size: 0.45em;
          margin-right: 10px;
          text-align: center;
        }

        a {
          color: $brand-primary;
        }
      }

      h5 {
        text-align: center !important;
        margin: 0;
        font-size: 1.5em;
      }

      a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
      }

      // footer Social icons
      ul.socialIcons {
        margin-top: $spacer;
        justify-content: center;

        a {
          background-color: $brand-primary;
        }
      }
    }
  }


  // Subsite - Toolbox Talks
  &.toolboxtalks {

    // Logo 
    .subsiteTitle {
      display: block;
      width: 200px;
      background-image: url($assets-path + "toolbox-talks-logo.jpg");
    }

    // feeds Layout
    @media (min-width: map-get($breakpoints, lg) + 1px) {

      // Feed - Not last
      .subsiteFeed:nth-child(-n+3) {
        width: 50%;
        flex: 1;
      }

      .subsiteFeedBox1 {
        flex: 2 !important;
      }
    }

    // Footer Boxes
    .subsiteFooterInner:not(.subsiteToolboxTalks) {
      display: none;
    }

    // Footer Icons
    .subsiteFooterInner.subsiteToolboxTalks:nth-of-type(1) h2:before {
      content: '\f041';
    }

    .subsiteFooterInner.subsiteToolboxTalks:nth-of-type(2) h2:before {
      content: '\f0e0';
    }

    .subsiteFooterInner.subsiteToolboxTalks:nth-of-type(3) h2:before {
      content: '\f095';
    }
  }


  // Subsite - ENDYS
  &.engadine-district-youth-service {

    // Logo 
    .subsiteTitle {
      display: block;
      background-image: url($assets-path + "logo-edys.png");
      width: 200px;
    }

    // feeds Layout
    @media (min-width: map-get($breakpoints, lg) + 1px) {

      // Feed - Not last
      .subsiteFeed:nth-child(-n+2) {
        width: 50%;
        flex: 3;
      }

      .subsiteFeedBox1 {
        flex: 4 !important;
      }
    }

    // Footer Boxes
    .subsiteFooterInner:not(.subsiteEdys) {
      display: none;
    }

    // Footer Icons
    .subsiteFooterInner.subsiteEdys:nth-of-type(1) h2:before {
      content: '\f041';
    }

    .subsiteFooterInner.subsiteEdys:nth-of-type(2) h2:before {
      content: '\f0e0';
    }

    .subsiteFooterInner.subsiteEdys:nth-of-type(3) h2:before {
      content: '\f095';
    }
  }

  &.sutherland-shire-walk-for-respectful-relationships {
    .headerTextSubsite {
      pointer-events: none;
      z-index: 999;
    }

    .subsiteTitle {
      display: block;
      background-image: url($assets-path + "walk-logo.png");
      width: 210px;
      pointer-events: all;
    }

    .FinancialSupportersandCommitteemembersFeed {
      .feedItemDetailsWrapper img {
        margin: 10px;
      }

      &:hover .feedItemDetailsWrapper {
        background-color: unset !important;
      }
    }

    .pageFooterWrapper {
      background-color: $brand-primary;

      * {
        color: #000 !important;
      }
    }

    .subsiteFooterInner {
      &:not(.subsiteWalk) {
        display: none;
      }

      a[href^="mailto"]:before {
        font-family: FontAwesome;
        content: "\f0e0";
        margin-right: 20px;
      }

      a[href*="instagram"] {
        margin-top: 20px;

        &:before {
          font-family: FontAwesome;
          content: "\f16d";
          margin-right: 20px;
        }
      }

      a[href*="facebook"] {
        &:before {
          font-family: FontAwesome;
          content: "\f082";
        }
      }
    }
  }

  &.ssdvc {
    .subsiteTitle {
      display: block;
      background-image: url($assets-path + "logo-subsite-ssdvc.svg");
      width: 210px;
      pointer-events: all;
    }

    .pageFooterWrapper {
      background-color: $brand-primary;

      * {
        color: black !important;
      }
    }

    .subsiteFooterInner {
      &:not(.subsiteSsdvc) {
        display: none;
      }

      h2 {
        flex-wrap: wrap;

        a {
          @media (max-width: 600px) {
            font-size: 1rem;
            transform: translateX(-50px);
          }
        }
      }

      a[href^="mailto"]:before {
        font-family: FontAwesome;
        content: "\f0e0";
        margin-right: 20px;
      }

      a[href*="facebook"] {
        margin-left: 50px;

        &:before {
          font-family: FontAwesome;
          content: "\f082";
          margin-right: 20px;
        }
      }

      a[href^="tel:"]:before {
        font-family: FontAwesome;
        content: "\f095";
        margin-right: 20px;
        margin-left: 50px;
      }
    }
  }

  // Golf subsite
  &.golf {
    .subsiteTitle {
      background-image: url($assets-path + "logo-golf-new.png");
      width: 200px;
    }

    .pageFooterWrapper {
      background-color: $brand-secondary;
    }

    .pageFooter {
      display: grid;
    }

    .footerBox.SubsiteFooter {
      display: none !important;
    }

    .footerBox:not(.SubsiteFooter) {
      display: block;
    }

    @media (min-width: map-get($map: $breakpoints, $key: lg)) {

      .homeFeedBox1,
      .homeFeedBox4,
      .homeFeedBox5 {
        width: 50%;
      }

      .homeFeedBox2,
      .homeFeedBox3 {
        width: 25%;
      }

      .homeFeed.subsiteFeed .feedsTitle {
        font-size: 1rem;
      }
    }
  }

  &.galadinner {
    .subsiteTitle {
      background-image: url($assets-path + "gala-dinner-logo.png");
      width: 200px;
    }

    .pageFooterWrapper {
      background-color: $brand-secondary;
    }

    .pageFooter {
      display: grid;
    }

    .footerBox.SubsiteFooter {
      display: none !important;
    }

    .footerBox:not(.SubsiteFooter) {
      display: block;
    }

    @media (min-width: map-get($map: $breakpoints, $key: lg)) {

      .homeFeedBox1,
      .homeFeedBox4,
      .homeFeedBox5 {
        width: 50%;
      }

      .homeFeedBox2,
      .homeFeedBox3 {
        width: 25%;
      }

      .homeFeed.subsiteFeed .feedsTitle {
        font-size: 1rem;
      }
    }
  }
}

.FinancialSupportersandCommitteeMembersFeed {
  .feedItemDetailsWrapper {
    background-color: transparent !important;
  }

  img {
    padding: 10px;
  }
}

.subsiteWalk {
  a[href*="instagram"] {
    margin-top: 0 !important;
    margin-left: 50px;
    margin-right: 20px;
  }
}

.mainLogo {
  margin-top: 10px;
}

.header-search .searchContainer input {
  display: none;
}

// Carousel bespoke
@media (min-width: 1025px) {
  body.homepage {
    .carouselSlideTitle {
      width: 70%;
      float: right;
    }

    .carouselDetailWrapper {
      justify-content: flex-end;
      margin-left: 0;
      width: 40%;
      max-width: 600px;
      background-color: $brand-secondary;

      &:after {
        content: '';
        background-image: url($assets-path + 'carousel-cap.svg');
        height: 100%;
        position: absolute;
        right: -100%;
        width: 100%;
        background-repeat: no-repeat;
      }
    }

    @media (max-width: 1320px) {
      .carouselImageMultiple .carouselDetailWrapper {
        padding-left: 20px;
        padding-right: 20px;
      }

      .carouselDetailWrapper {
        width: 50%;
      }
    }
  }
}